<template>
  <div id="p2p-market-buy-detail">

      <p2p-form 
        type="BUY"
        :currencyObj="currencyObj"
        :formId="$route.params.id || null"
      />

  </div>
</template>

<script>
import P2pForm from '../components/p2p-form.vue';
import { generalMixin } from "@/utils/general-mixin";
import { mapGetters } from "vuex";


export default {
  name: "P2PMarketBuyDetail",
  mixins: [generalMixin],
  components: {
    P2pForm
  },
  data() {
    return {
      currencyObj: {}
    };
  },
  computed: {
    ...mapGetters(["user","currencyList"]),
  },
  watch: {},
  methods: {
  },
  mounted() {
    this.currencyObj = this.currencyList.find(el => el.code === "myr")
  },
};
</script>
